<template>
  <div>
    <!--  导入坑位号表格弹出框  -->
    <el-dialog
      :title="platFormTitle"
      :visible.sync="dialogFormVisible"
      width="60%"
      class="dialog"
      center
    >
      <div class="contents1">
        <el-form
          :model="platForm"
          :rules="rules"
          ref="platForm"
          label-width="auto"
        >
          <el-form-item label="上传文件" prop="file">
            <el-upload
              :action="`${
                role == 'delivery' ? BASE.PRO3 : BASE.PRO4
              }/take/order/placeno/region/import`"
              :headers="{ token: token }"
              ref="upload"
              accept=".xlsx"
              :on-success="successFile"
              :on-error="errorFile"
              :before-remove="beforeRemove"
              :on-exceed="onExceed"
              :on-change="onChange"
              class="upload-demo"
              :multiple="false"
              :limit="1"
              :auto-upload="false"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
          <div>需先导出文件，在原文件基础上修改坑位号后再上传；</div>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFrom" :loading="submitLoading"
          >确认</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import debounce from "lodash/debounce";
import { BASE } from "@/api";

export default {
  props: ["id"],
  name: "uploadFile",
  data() {
    return {
      BASE,
      role: sessionStorage.getItem("manageIsdentity"),
      token: sessionStorage.getItem("token"),
      submitLoading: false,
      dialogFormVisible: false, //编辑弹窗
      platFormTitle: "", //弹框title
      fileList: [],
      platForm: {
        file: "",
        id: "",
        name: "",
      },
      rules: {
        file: [{ required: true, message: "请上传文件", trigger: "change" }],
      },
    };
  },
  created() {},
  methods: {
    /**
     * 超出数量处理
     */
    onExceed() {
      this.$message.warning(`抱歉，最多只能上传1份文件`);
    },
    /**
     * 上传操作
     */
    successFile(e) {
      console.log(e, "e====");
      this.submitLoading = false;
      if (e.code == 20000) {
        this.dialogFormVisible = false;
        this.$message({
          type: "success",
          message: "导入成功",
        });
        this.$emit("dialogVisibleChange", ""); // 刷新列表
      } else {
        this.tool.message(e.msg, "error");
      }
    },
    /**
     * 上传失败
     */
    errorFile(e) {
      this.platForm.file = "";
      this.$message({
        type: "error",
        message: "上传失败",
      });
    },
    /**
     * 删除文件
     */
    beforeRemove(file, fileList) {
      this.fileList = [];
      this.$refs.upload.clearFiles();
    },
    onChange(file) {
      console.log(file, this.fileList, "fileList==");
      // 可以在这里对文件进行一些验证
      this.fileList.push(file);
    },
    /**
     *导入
     */
    addData() {
      this.platFormTitle = "导入坑位号";
      this.submitLoading = false;
      this.platForm = {
        file: "",
      };
      this.dialogFormVisible = true;
      this.fileList = [];
      this.$nextTick(() => {
        this.$refs.upload.clearFiles();
      });
    },
    /**
     * 提交表单
     */
    submitFrom: debounce(function () {
      console.log(this.fileList, this.$refs.upload, "==");
      if (this.fileList.length == 0) {
        this.tool.message("请上传文件", "error");
        return;
      } else {
        this.submitLoading = true;
        this.$refs.upload.submit(); // 触发上传
      }
    }, 300),
  },
};
</script>
<style scoped lang="scss">
.contents1 {
  box-sizing: border-box;
  padding: 10px 26px 40px;

  .hide {
    /deep/ .el-upload--picture-card {
      display: none;
    }
  }
}
</style>
